// Tooltip.tsx
import React, { useState, useImperativeHandle, forwardRef, ReactNode } from 'react';
import './tooltip.css';

/**
 * Tooltip component.
 * This component is used to show a tooltip message on hover over its children.
 *
 * Note: In order for the tooltip to be visible completely,
 * its parent elements should have 'overflow: visible;' set in their styles.
 *
 *
 * Props:
 * - message: The message to be shown in the tooltip.
 * - children: The child components over which the tooltip will be shown.
 */
interface TooltipProps {
  message: string;
  extraClass?: string;
  children: ReactNode;
}

/**
 * Interface for the ref handle.
 * This handle exposes the 'showTooltip' function which can be used to programmatically show the tooltip.
 */
export interface TooltipHandle {
  showTooltip: () => void;
}

export const Tooltip = forwardRef<TooltipHandle, TooltipProps>(({ message, extraClass, children }, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    showTooltip() {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 1100);
    }
  }));

  return (
    <div className="tooltip-container">
      {children}
      <span className={`tooltip-text ${visible ? 'show' : ''} ${extraClass ? extraClass : ''}`}>{message}</span>
    </div>
  );
});
