import React, {FunctionComponent} from "react";
import Button, {Icon} from "../button";
import {FormattedMessage} from "react-intl";

type NftButtonProps = {
  toPage: string;
  buttonTextId: string;
};

const GetNftButton: FunctionComponent<NftButtonProps> = ({
  toPage,
  buttonTextId= "btn.nft.get"
}) => {
  return <Button
    className={""}
    external={false}
    gradient={true}
    to={toPage}
  >
    <FormattedMessage id={buttonTextId} />
  </Button>
};

export default GetNftButton;
