import React, {FunctionComponent as FC} from "react";
import Divider from "./divider";

type SectionProps = {
  title: string;
  children: any;
  className?: string;
  anchorTag?: string;
  margin?: boolean;
};

/**
 * This component represents a section of content within the stamp overview pages.
 */
const Section: FC<SectionProps> = ({
  title,
  children,
  margin,
  anchorTag,
  className = ""
}) => (
  <div id={anchorTag} className={className}>
    <h2 className="rfs-small text-faint font-weight-normal mb-1">{title}</h2>
    <Divider margin={margin} />
    {children}
  </div>
);

export default Section;
