import React, {FunctionComponent as FC} from "react";
import {FormattedMessage} from "react-intl";

type TitleAreaProps = {
  title: string;
  rubric: string;
  sequentialId: string;
  collectionCount: number;
};

const TitleArea: FC<TitleAreaProps> = ({
  title,
  sequentialId,
  collectionCount,
  rubric
}) => {
  return (<div className="mb-md-5">
    <div className="row">
      <div className="col-12">
        <h1 className="rfs-title font-weight-normal">{title}</h1>
      </div>
    </div>
    <div className="row rfs-small d-none d-md-flex">
      <div className="col-12 col-md-5">
        <span className="text-turquoise">{sequentialId}</span>{" "}
        <FormattedMessage id="plain.of" /> {collectionCount}
      </div>
      <div className="col-12 col-md-7 text-faint text-right">
        <FormattedMessage id="stamp.rubric" values={{ rubric }} />
      </div>
    </div>
    <div className="row rfs-small d-flex d-md-none">
      <div className="col-12 col-md-5 ">
        <span className="text-turquoise">{sequentialId}</span>{" "}
        <FormattedMessage id="plain.of" /> {collectionCount}
      </div>
      <div className="col-12 text-faint">
        <FormattedMessage id="stamp.rubric" values={{ rubric }} />
      </div>
    </div>
  </div>);
};

export default TitleArea;
