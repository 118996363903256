import React from "react";
import {FormattedMessage} from "react-intl";
import Button, {ButtonColor} from "./button";

const PuzzleDetails = () => {
  return (
    <>
      <p className="rfs-large font-weight-bold mb-2 mt-4">
        <FormattedMessage id="section.puzzle.title" />
      </p>
      <p className="rfs-large">
        <FormattedMessage id="section.puzzle.description" />
      </p>
      <div className="text-center text-md-right my-2 my-lg-0">
        <Button
          to="https://d2.vc/bm4/website/store"
          color={ButtonColor.Violet}
          external={true}
        >
          <FormattedMessage id="btn.download.app.android" />
        </Button>
        <Button
          to="https://d2.vc/bm4/website/app/ios"
          color={ButtonColor.Violet}
          external={true}
        >
          <FormattedMessage id="btn.download.app.ios" />
        </Button>
      </div>
    </>
  );
};

export default PuzzleDetails;
