import React, {useContext, useEffect} from "react";
import Error from "../components/error";
import {AssetContext} from "../provider/asset";
import {RootContext} from "../root";
import Loading from "../components/loading";
import StampDetails from "../components/stampdetails";
import {navigate} from "gatsby";

const Assets = () => {
  const {error} = useContext(RootContext);
  const {asset, collection, loading, collectionId, serialNumber, qrSuffix} = useContext(AssetContext);

  // Redirect to main page if stamp is not released yet.
  const releaseDate = collection ? collection.releaseDate.toDate() : new Date("2021-09-09T00:00:00+02:00");
  const printInfo = (date) => {
    console.info(`Stamp only released after ${date}.`);
    return true;
  };
  typeof window !== "undefined" && releaseDate && releaseDate > new Date() && printInfo(releaseDate) && navigate("/");

  if ((loading || !asset) && !error) {
    return <Loading />;
  } else if (error || !asset) {
    return <Error error={error} />;
  } else if (asset) {
    return <StampDetails asset={asset} collection={collection} serialNumber={serialNumber} qrSuffix={qrSuffix} collectionId={collectionId}/>;
  } else {
    return <Error error={"Unexpected state"} />;
  }
};

export default Assets;
