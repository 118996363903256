import React, {useContext, useEffect, useState} from "react";
import {StringParam, useQueryParam} from "use-query-params";
import {AssetCollectionDetails} from "../models/AssetCollectionDetails";
import {FirebaseContext} from "./firebase";

// TCollectionDetailsContext represents the data type that this
// provider will inject in its decendents.
interface TCollectionDetailsContext {
  collectionDetails: AssetCollectionDetails | undefined;
  loading: boolean | undefined;
  error: any;
}

export const CollectionDetailsContext =
  React.createContext<TCollectionDetailsContext>({
    collectionDetails: undefined,
    loading: true,
    error: null
  });

/*
 * The collection details provider is responsible for querying the backend if
 * a collection ID is given. If the collection details are already loaded it does nothing.
 */
export const CollectionDetailsProvider = ({children}) => {
  const [collectionId] = useQueryParam("collectionId", StringParam);
  const {firebase} = useContext(FirebaseContext);
  const [collectionDetails, setCollectionDetails] =
    useState<AssetCollectionDetails>(null);
  const [error, setError] = useState<AssetCollectionDetails>(null);

  // If the data was already loaded default to `loading` == false
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchCollectionDetails = async () => {
      try {
        const firestore = firebase.firestore();

        setLoading(collectionDetails === undefined);
        setError(null);

        const collectionDetailsResult = await firestore
          .collection("assetCollectionDetails")
          .doc(collectionId)
          .get();

        if (!collectionDetailsResult.exists) {
          throw new Error(
            `CollectionDetails not found for collection ID ${collectionId}`
          );
        }

        const collectionDetailsData =
          collectionDetailsResult.data() as AssetCollectionDetails;

        setCollectionDetails(collectionDetailsData);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Only query the backend if the collection details was not already loaded.
    // This is necessary to not fetch the collection details again when navigating
    // back and forth to the details page.
    if (!collectionDetails && collectionId && firebase) {
      fetchCollectionDetails();
    }
  }, [collectionId, firebase]);

  return (
    <CollectionDetailsContext.Provider
      value={{
        collectionDetails,
        loading,
        error
      }}
    >
      {children}
    </CollectionDetailsContext.Provider>
  );
};
