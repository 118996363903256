import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState
} from "react";
import {Figure} from "../models/Figure";
import {FirebaseContext} from "./firebase";

// TFigureContext represents the data type that this
// provider will inject in its decendents.
interface TFigureContext {
  figure: Figure | undefined;
  loading: boolean | undefined;
  error: any;
}

export const FigureContext = React.createContext<TFigureContext>({
  figure: undefined,
  loading: true,
  error: undefined
});

type FigureProviderProps = {
  figureId: string;
};

/*
 * The figure provider is responsible for querying the backend if
 * a collection ID and serial number are given. If the asset plus
 * its associated collection are already loaded it does nothing.
 * If either is not given, it does nothing.
 */
export const FigureProvider: FC<FigureProviderProps> = ({
  children,
  figureId
}) => {
  const {firebase} = useContext(FirebaseContext);
  const [figure, setFigure] = useState<Figure>(null);
  const [error, setError] = useState<Figure>(null);

  // If the data was already loaded default to `loading` == false
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchFigure = async () => {
      try {
        setLoading(figure === null);
        setError(null);

        const figuresResult = await firebase
          .firestore()
          .collection("figures")
          .doc(figureId)
          .get();

        if (!figuresResult.exists) {
          throw new Error(`Figure not found for ID ${figureId}`);
        }

        setFigure(figuresResult.data() as Figure);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    // Only query the backend if the figure was not already loaded.
    // This is necessary to not fetch the figure again when navigating
    // back and forth.
    if (!figure && firebase) {
      fetchFigure();
    }
  }, [figureId, firebase]);

  return (
    <FigureContext.Provider value={{figure, loading, error}}>
      {children}
    </FigureContext.Provider>
  );
};
