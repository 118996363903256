import React from "react";
import styled from "styled-components";
import Divider from "./divider";

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: ${props => props.height || '70vh'};
  align-items: center;
  flex-direction: column;
`;

const Loading = ({ height }) => (
  <>
    <Container height={height}>
      <div className="m-spinner--swirl"></div>
    </Container>
    <div className="container">
      <Divider />
    </div>
  </>
);

export default Loading;
