import React, {FunctionComponent as FC} from "react";
import {useIntl} from "react-intl";
import {Markdown} from "./markdown";
import Section from "./section";

type DesignerInfoProps = {
  description: string;
};

const DesignerInfo: FC<DesignerInfoProps> = ({description}) => {
  const intl = useIntl();

  return (
    <Section
      title={intl.formatMessage({id: "section.about.designer"})}
      className="my-5"
      margin={false}
    >
      <div className="mt-3 mb-0">
        <Markdown content={description} />
      </div>
    </Section>
  );
};

export default DesignerInfo;
