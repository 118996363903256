import React, {FunctionComponent as FC, useContext} from "react";
import {Helmet} from "react-helmet";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import Section from "../components/section";
import TitleArea from "../components/titlearea";
import {isIos} from "../hooks/mobile";
import {Asset} from "../models/Asset";
import {AssetCollection} from "../models/AssetCollection";
import {AssetEditionType} from "../models/AssetEditionType";
import {CollectionDetailsProvider} from "../provider/collection-details";
import {FigureProvider} from "../provider/figure";
import {LanguageContext} from "../provider/language";
import AssetFigure from "./assetfigure";
import CollectionDetails from "./collection-details";
import CollectionDetailsContent from "./collection-details-content";
import DesignerInfo from "./designer-info";
import FAQLink from "./faqs/faq-link";
import PuzzleDetails from "./puzzledetails";
import GetNftButton from "./get-nft-button";
import Button from "./button";

const StampImg = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
`;

const StyledDiv = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

type StampDetailsProps = {
  asset: Asset;
  collection: AssetCollection;
  serialNumber: string;
  qrSuffix: string;
  collectionId: string;
};

const StampDetails: FC<StampDetailsProps> = ({ asset, collection, serialNumber, qrSuffix, collectionId }) => {
  const { locale } = useContext(LanguageContext);
  const intl = useIntl();
  const isSmallSeries = collection.assetEdition.type == AssetEditionType.SmallSeries;

  const nftButtonToPage = `/claim-nft?collectionId=${collectionId}&serialNumber=${serialNumber}&qrSuffix=${qrSuffix}`;
  return (
    <>
      <FormattedMessage id="title.asset">
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <div className="container my-4">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-6">
            <div className="row" style={{ justifyContent: "center" }}>
              <div>
                <StampImg
                  className="mb-4"
                  src={collection.imageUrl}
                  alt={collection.imageAlt[locale]}
                />
              </div>
            </div>
            {qrSuffix && (<div className="row"  style={{ justifyContent: "center" }}>
              <div className="col-12 text-center pt-3 pb-3">
                <Button gradient={true} disabled={true}>
                  <FormattedMessage id="btn.cryptostamp.show" />
                </Button>
              </div>
            </div>)}
            {qrSuffix && (<div className="row" style={{ justifyContent: "center", textAlign: "center" }}>
              <div className="col-12 pb-4">
                <GetNftButton toPage={nftButtonToPage} buttonTextId={"btn.nft.show"} />
              </div>
            </div>)}
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <TitleArea
              title={collection.title[locale]}
              collectionId={asset.assetCollection.id}
              serialNumber={serialNumber}
              sequentialId={asset.sequentialId}
              qrSuffix={qrSuffix}
              collectionCount={collection.count}
              rubric={collection.rubric[locale]}
            />
            <Section
              title={intl.formatMessage({id: "section.stamp.details"})}
              className="mt-4"
            >
              <CollectionDetails collection={collection} sequentialId={asset.sequentialId} />
            </Section>
            {isIos() && (
              <div className="mt-5">
                <FigureProvider figureId={asset.figure.id}>
                  <AssetFigure isSmallSeries={isSmallSeries} />
                </FigureProvider>
              </div>
            )}
          </div>
        </div>
        {!isIos() && (
          <div className="row">
            <div className="container mt-4 mt-md-5 mb-4">
              <div className="row">
                <StyledDiv className="col-12 col-md-4 col-lg-6 d-flex flex-row pb-4 pb-md-0">
                  <FigureProvider figureId={asset.figure.id}>
                    <AssetFigure isSmallSeries={isSmallSeries}/>
                  </FigureProvider>
                </StyledDiv>
                {!isSmallSeries && (
                  <div className="col-12 col-md-8 col-lg-6 order-md-2 mb-2 my-md-0">
                    <Section title={intl.formatMessage({id: "section.puzzle"})}>
                      <PuzzleDetails/>
                    </Section>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <CollectionDetailsProvider>
          <div className="pb-4">
            <CollectionDetailsContent />
          </div>
        </CollectionDetailsProvider>
        <DesignerInfo description={collection.designerInfo.about[locale]} />
        <FAQLink />
      </div>
    </>
  );
};

export default StampDetails;
