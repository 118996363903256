/**
 * Represents content sections about the corresponding asset collection.
 *
 * The document ID is the asset collection ID.
 *
 * These sections are rendered, e.g., on the website in the asset detail view, but are not
 * limited to that location, and may be used in the future also in the app.
 */
export interface AssetCollectionDetails {
  contentSections: AssetCollectionDetailsContentSection[];
  /**
   * Design information for creating event trails for an asset.
   */
  eventTrail: AssetCollectionDetailsEventTrail;
}

export interface AssetCollectionDetailsContentSection {
  /**
   * Section content
   */
  content: AssetCollectionDetailsContent;
  /**
   * Image to be rendered on the left of the content section. Only applicable if
   * type=TEXT_WITH_IMAGE.
   */
  image?: AssetCollectionDetailsImage;
  /**
   * Whether the section is rendered in pinned mode. It means that the section is always open
   * and not collapsible.
   */
  pinned?: boolean;
  /**
   * Sort key the content sections are ordered by for rendering in descending order.
   */
  sortKey: number;
  /**
   * Title of the content section that is rendered as the caption of the section collapsible.
   */
  title: I18NString;
  /**
   * Type of the content section. Two types are available: Simple markdown text, or text with
   * an image on the left.
   */
  type: Type;
}

/**
 * Section content
 */
export interface AssetCollectionDetailsContent {
  /**
   * Text content in markdown format.
   */
  markdown: I18NString;
}

/**
 * Text content in markdown format.
 *
 * Title of the content section that is rendered as the caption of the section collapsible.
 */
export interface I18NString {
  de: string;
  en: string;
}

/**
 * Image to be rendered on the left of the content section. Only applicable if
 * type=TEXT_WITH_IMAGE.
 */
export interface AssetCollectionDetailsImage {
  altText: I18NString;
  /**
   * Image URL
   */
  url: string;
}

/**
 * Type of the content section. Two types are available: Simple markdown text, or text with
 * an image on the left.
 */
export enum Type {
  Text = "TEXT",
  TextWithImage = "TEXT_WITH_IMAGE",
}

/**
 * Design information for creating event trails for an asset.
 */
export interface AssetCollectionDetailsEventTrail {
  /**
   * Subitem for the defined template settings.
   */
  template: AssetCollectionDetailsEventTrailTemplate;
}

/**
 * Subitem for the defined template settings.
 */
export interface AssetCollectionDetailsEventTrailTemplate {
  /**
   * Background color for the EAPI site. (HEX or color name allowed)
   */
  backgroundColor: string;
  /**
   * Image settings for the share link.
   */
  image: AssetCollectionDetailsEventTrailTemplateImage;
  text?: I18NString;
  /**
   * Text color for the EAPI site. (HEX or color name allowed)
   */
  textColor: string;
}

/**
 * Image settings for the share link.
 */
export interface AssetCollectionDetailsEventTrailTemplateImage {
  altText: I18NString;
  /**
   * Image URL or Base64 encoded string of the image itself.
   */
  url: string;
}
