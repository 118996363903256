import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {FigureContext} from "../provider/figure";
import {LanguageContext} from "../provider/language";

interface HasError {
  error?: any;
}

const Container = styled.div<HasError>`
  flex: 1;
  height: 240px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: ${(props) =>
    props.error
      ? "linear-gradient(67deg, #252f4a, #791b2c)"
      : "linear-gradient(108deg, #e73cde, #49b4e6)"};

  @media (max-width: ${(props) =>
      props.theme.breakpointsNo.lg - 1 + "px"}) and (min-width: ${(props) =>
      props.theme.breakpoints.md}) {
    height: 200px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 40vw;
    min-height: 160px;
  }
`;

const Icon = styled.img`
  width: 35%;
  max-width: 100px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 23%;
  }
`;

const Note = styled.div`
  font-size: 14px;
  text-align: center;
  max-width: 80%;

  @media (max-width: ${(props) => props.theme.breakpointsNo.lg - 1 + "px"}) {
    font-size: 12px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  max-width: 80%;

  @media (max-width: ${(props) => props.theme.breakpointsNo.lg - 1 + "px"}) {
    font-size: 14px;
  }
`;
const AssetFigure = ({isSmallSeries = false}) => {
  const {locale} = useContext(LanguageContext);
  const {figure, loading, error} = useContext(FigureContext);
  if ((loading || !figure) && !error) {
    return (
      <Container>
        <div className="m-spinner--swirl mb-3"></div>
        <Note>
          <FormattedMessage id="section.puzzle.information" />
        </Note>
      </Container>
    );
  } else if (figure) {
    // Construct the name with a noun, e.g. "the castle" or "das Schloss".
    const figureName = figure.article[locale] + " " + figure.name[locale];
    // Capitalize first letter to use name in a title description: "The castle" or "Das Schloss".
    const figureNameCapitalized =
      figureName.charAt(0).toUpperCase() + figureName.slice(1);
    const figureIcon = figure.iconUrl.white;

    return (
      <Container>
        <Icon src={figureIcon} alt={figure.iconAlt[locale]} />
        <Title className="my-1">{figureNameCapitalized}</Title>
        <Note>
          {isSmallSeries ? (
            // Stamp belonging to a Small Series has no puzzle and should not mention anything about puzzles.
            // TODO refactor to a small-series specific message
            <FormattedMessage
              id="section.puzzle.information.ios"
              values={{figureName}}
            />
          ) : (
            <FormattedMessage id="section.puzzle.information" />
          )}
        </Note>
      </Container>
    );
  } else {
    return <Container error={error}>{`${error}`}</Container>;
  }
};

export default AssetFigure;
