import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import {
  AssetCollectionDetailsContentSection,
  Type
} from "../models/AssetCollectionDetails";
import {LanguageContext} from "../provider/language";
import Divider from "./divider";
// @ts-ignore
import ChevronIcon from "./faqs/chevron_right.svg";
import {Markdown} from "./markdown";

interface Selectable {
  isSelected: boolean;
}

interface Expandable {
  isExpanded: boolean;
  contentHeight?: number;
  titleHeight?: number;
}

const StyledRow = styled.div<Expandable>`
  position: relative;
  min-height: "50px";
  transition: all 200ms;
  max-height: ${(props) =>
    props.isExpanded
      ? `${props.contentHeight + 32}px`
      : `${props.titleHeight + 32}px`};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-height: ${(props) =>
      props.isExpanded
        ? `${props.contentHeight + props.titleHeight + 32}px`
        : `${props.titleHeight + 32}px`};
  }
`;

const StyledIcon = styled.img<Selectable>`
  position: absolute;
  right: 10px;
  top: 16px;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.15s ease-out;
  transform: ${(props) => (props.isSelected ? `rotate(90deg)` : "")};
`;

const StyledAnswer = styled.div<{isExpanded}>`
  transition: all 200ms;
  opacity: ${(props) => (props.isExpanded ? "1.0" : "0.0")};
`;

type CollapsableCollectionSectionsProps = {
  collectionSections: AssetCollectionDetailsContentSection[];
};

const CollapsableCollectionSections: FC<CollapsableCollectionSectionsProps> = ({
  collectionSections
}) => {
  const {locale} = useContext(LanguageContext);
  const [expanded, setExpanded] = useState<number>(null);

  // Trigger rerender to apply right height to Content
  const [dummy, setDummy] = useState<boolean>(null);

  const titlRefs = useRef([]);
  const contentRefs = useRef([]);

  useEffect(() => {
    // TODO: Find a better way to aplly the right hight when content
    // gets prefetched and not loaded from firebase anymore
    setInterval(() => setDummy(false), 100);
  }, []);

  if (collectionSections.length === 0) return null;

  return (
    <div id="accordion">
      <Divider margin={false} />
      {collectionSections.map((section, i) => {
        return (
          <div key={i}>
            <div className="container">
              <StyledRow
                className="row py-3"
                onClick={() => setExpanded(expanded == i ? null : i)}
                isExpanded={i === expanded}
                contentHeight={contentRefs.current[i]?.scrollHeight}
                titleHeight={titlRefs.current[i]?.scrollHeight}
              >
                <div className="col-12 col-md-6">
                  <div
                    className="rfs-large"
                    ref={(el) => (titlRefs.current[i] = el)}
                  >
                    {section.title[locale]}
                  </div>
                </div>
                <StyledAnswer
                  className={"col-12 col-md-5"}
                  isExpanded={i === expanded}
                  ref={(el) => (contentRefs.current[i] = el)}
                >
                  <div className="mt-3 mt-md-0">
                    <Markdown content={section.content.markdown[locale]} />
                  </div>

                  {section.type === Type.TextWithImage && (
                    <img
                      className="w-100"
                      src={section.image.url}
                      alt={section.image.altText[locale]}
                    />
                  )}
                </StyledAnswer>
                <StyledIcon isSelected={expanded === i} src={ChevronIcon} />
              </StyledRow>
            </div>
            <Divider isSelected={expanded === i} margin={false} />
          </div>
        );
      })}
    </div>
  );
};

export default CollapsableCollectionSections;
