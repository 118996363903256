import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState
} from "react";
import styled from "styled-components";
import {
  AssetCollectionDetailsContentSection,
  Type
} from "../models/AssetCollectionDetails";
import {CollectionDetailsContext} from "../provider/collection-details";
import {LanguageContext} from "../provider/language";
import CollapsableSections from "./collapsable-collection-sections";
import Error from "./error";
import Loading from "./loading";
import {Markdown} from "./markdown";
import Section from "./section";

export const StyledImage = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
`;

const CollectionDetailsContent: FC = () => {
  const {locale} = useContext(LanguageContext);
  const {collectionDetails, loading, error} = useContext(
    CollectionDetailsContext
  );
  const [pinnedSections, setPinnedSections] = useState<
    AssetCollectionDetailsContentSection[]
  >([]);
  const [collapsableSections, setOtherSections] = useState<
    AssetCollectionDetailsContentSection[]
  >([]);

  useEffect(() => {
    if (collectionDetails) {
      setPinnedSections(
        collectionDetails.contentSections.filter((details) => details.pinned)
      );
      setOtherSections(
        collectionDetails.contentSections.filter((details) => !details.pinned)
      );
    }
  }, [collectionDetails]);

  const TextWithImage = (section: AssetCollectionDetailsContentSection) => {
    return (
      <div className="row my-5">
        <div className="col-12 col-md-4 col-lg-6 mb-md-0 mb-3">
          <StyledImage
            src={section.image.url}
            alt={section.image.altText[locale]}
          />
        </div>
        <div className="col-12 col-md-8 col-lg-6 my-auto">
          <div className="rfs-large text-center">
            <Markdown content={section.content.markdown[locale]} />
          </div>
        </div>
      </div>
    );
  };

  const MarkdownText = (section: AssetCollectionDetailsContentSection) => {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <Markdown content={section.content.markdown[locale]} />
          </div>
        </div>
      </div>
    );
  };

  const PinnedSections = () => (
    <>
      {pinnedSections.map((section, index) => (
        <Section title={section.title[locale]} key={index}>
          {section.type === Type.TextWithImage && TextWithImage(section)}
          {section.type === Type.Text && MarkdownText(section)}
        </Section>
      ))}
    </>
  );

  if ((loading || !collectionDetails) && !error) {
    return <Loading />;
  } else if (
    pinnedSections ||
    collapsableSections ||
    (pinnedSections && collapsableSections)
  ) {
    return (
      <>
        <PinnedSections />
        {collapsableSections && (
          <CollapsableSections collectionSections={collapsableSections} />
        )}
      </>
    );
  } else {
    return <Error error={error} errorMsg={error.message} showButton={false} />;
  }
};

export default CollectionDetailsContent;
